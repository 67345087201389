/* main */

body {
  padding: 0px 0 20px 0px;
  margin: 0px;
  font: 16px 'Open Sans', sans-serif;
  color: #A3BFC6 ;
  font-weight: normal;
  background: #222A30;
  -webkit-font-smoothing: subpixel-antialiased;
}



h1, h2, h3, h4, h5, h6 {
  color:#EC4E3B;
  margin: 0 0 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
}

p, ul, ol, table, pre, dl {
  margin: 0 0 20px;
}

h1, h2, h3 {
  line-height: 1.2;
}

h1 {
  font-size: 28px;
  color: #B3D1F0;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
  line-height: 24px;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  color: #b6b6b6;
}

a {
  color: #49B1F7;
  font-weight: 400;
  text-decoration: none;
}

a:hover {
  color: #EC4E3B;
}

a:hover, a:active, a:focus, a:visited  {
  text-decoration: none;
  outline: 0;
}

.wrapper {
  max-width: 750px;
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
}

section img {
  max-width: 100%;
}

blockquote {
  border-left: 3px solid #ffcc00;
  margin: 0 1 auto;
  padding: 0 0 0 20px;
  font-style: italic;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  text-align: left;
  padding: 5px 10px;
  border-bottom: 1px solid #424242;
  color: #b6b6b6;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
}

td {
  text-align: left;
  padding: 5px 10px;
  border-bottom: 1px solid #434343;
}

hr {
  height: 0;
  border: 0;
  border-bottom: 1px dashed #49B1F7;
}

dt {
  color: #F0E7D5;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
}

section {
  padding: 20px 0px 50px 0px;
  margin: 20px 0;

  #header {
    border: 0;
    outline: none;
    margin: 0 0 20px 0;
    padding: 0 0 5px 0;

    h1 {
      font-family: 'Open Sans', sans-serif;
      font-weight: 100;
      font-size: 38px;
      text-align: center;
    }

    p {
      color: #B3D1F0;
      font-family: 'Open Sans', sans-serif;
      font-weight: 100;
      font-size: 18px;
      text-align: center;
    }

    .credits {
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
      font-weight: normal;
      color: #696969;
      margin-top: -25px;
    }
  }

  #header, #footer {
    .social {
      a {
        color: #fff;
        font-size: 17px;
        padding-left: .25em;
      }
    }
  }

  .p-title{
    color: #49B1F7;
  }
}

.section-header-archive, .tag_name{
	margin:  15px 0 0 0;
}

.paginate{
  margin-bottom: 15px;
}

.resume p{
  font-size: 15px;
  line-height: 24px;
  margin: 0 0 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  color: #A3BFC6;
}

.tags{
 font-size: 13px;
}

.tags a {
  display: inline-block;
  margin: 0 10px 10px 0;
}

/* for circled image */
.circular {
  width: 300px;
  height: 300px;
  border-radius: 150px;
  -webkit-border-radius: 150px;
  -moz-border-radius: 150px;
  margin-bottom: 15px;
}

#header #sitename,
.resume span,
.resume h2 {
  color: #EC4E3B;
}

#sitename{
  font-weight: 300;
}

mark, .mark {
  opacity: 0.9;
}

.orng{
  color: #EC4E3B;
}
/* tag_box */
.tag_box {
  list-style: none;
  margin: 0;
  overflow: hidden;
  font-size: 14px;}
.tag_box li {
  line-height: 28px; }
.tag_box li i {
  opacity: 0.9; }
.tag_box.inline li {
  float: left; }
.tag_box a {
  padding: 2px 4px;
  margin: 4px;
  background: #222A30;
  color: #B3D1F0;
  border-radius: 3px;
  text-decoration: none;
  border: 1px dashed #cccccc; }
.tag_box a span {
  vertical-align: super;
  font-size: 0.8em; }
.tag_box a:hover {
  background-color: #e5e5e5;
  color: #222A30}
.tag_box a.active {
  background: #57A957;
  border: 1px solid #4c964d;
  color: #FFF; }

.bshare-custom a { color: #e5e5e5 !important; }
.bshare-custom a:hover { color: #e02d2d !important; }
